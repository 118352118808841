import React from 'react';

function HowToPlay() {
  return (
    <section className="text-center mb-12 m-20">
      <h1 className="text-left text-5xl mb-4">How to Play</h1>

      <h4 className="text-left text-2xl mb-4">
        Satta is a broad word to describe “betting” in India or Hindi. The Matka game is sometimes referred to as Indian Satta Matka because of its early popularity. If you are interested in betting games, you can check out the numbers game.
        <br /><br />
        The game of online satta matka is pretty simple to understand and play. With very little study and effort, you can become an online matka play king and earn a huge amount. The Kalyan matka game, which is the online matka play market, was the first online matka market introduced by Kalyanji Bhagat in 1962, and the online matka Worli market was introduced in the 1970s by Ratan Khatri.
      </h4>

      <h2 className="text-left text-3xl mb-4 mt-7">How to Play the Game</h2>
      <h4 className="text-left text-2xl mb-4">
        1. Pick three numbers from 0–9. For example, 5,3,6 would be your first picked random numbers.
        <br /><br />
        2. Add the numbers together (5 + 3 + 6 = 14). You only keep the last digit of the sum, which is 4. So, your first draw would be 5,3,6 *4.
        <br /><br />
        3. For the second set of numbers, the process is the same. Let's say 8,2,8 gives a sum of 18. Keep the last digit, which is 8. Your second draw would be 8,2,8 *8.
        <br /><br />
        4. The final card looks like this: 5,3,6 *4 X 8,2,8 *8.
      </h4>

      <h2 className="text-left text-3xl mb-4 mt-7">How to Add Money</h2>
      <h4 className="text-left text-2xl mb-4">
        1. Minimum Deposit is 100/- Rs
        <br /><br />
        2. Minimum Withdrawal is 500/- Rs
        <br /><br />
        3. Maximum Withdrawal Is 25 Lakh Per Day
        <br /><br />
        4. Withdrawal requests can be placed between 11 am to 11 pm, all 7 days.
        <br /><br />
        5. Withdrawals are not available on festivals.
        <br /><br />
        6. Points system: 1 point = Rs. 1/-
        <br /><br />
        7. Apply for withdrawal requests on the Kuber555 Mobile App.
        <br /><br />
        For assistance, contact us via WhatsApp: +91 9694776485
      </h4>

      <h2 className="text-left text-3xl mb-4 mt-7">Winning at Satta Matka</h2>
      <h4 className="text-left text-2xl mb-4">
        To win at Matka Satta, you can place bets on various number combinations. The game relies heavily on chance, and payouts can range from 9/1 to 999/1. Although the game is purely based on luck, many players have their superstitions regarding numbers.
      </h4>
    </section>
  );
}

export default HowToPlay;
