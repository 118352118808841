import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Button } from "./components/Button"; // Update to correct path

 // Icon package remains the same
import { Link } from "react-router-dom"; // Updated from Next.js Link to React Router Link
import './index.css';
import myImage from './logo192.png';
import Charts from './Charts';
import AboutUs from './AboutUs';
import HowToPlay from './HowToPlay';
import TermsAndConditions from './TermsAndConditions';
const handleClick = (link) => {
  window.open(link, '_blank'); // Opens in a new tab
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
<React.StrictMode>
<BrowserRouter>
<div className="min-h-screen bg-white">
      <header className="bg-teal-600 text-white">
        <div className="container mx-auto px-4 py-4 flex justify-between items-center">
          <div className="flex items-center space-x-2">
            <div className="w-8 h-8 bg-white rounded-full flex items-center justify-center text-teal-600 font-bold text-xl">
            <img src={myImage} alt="My Image" />
            </div>
            <span className="text-2xl font-bold">Kuber555.com</span>
          </div>
          <nav className="hidden md:flex space-x-4">
            <Link to="/" className="hover:underline">Home</Link>
            <Link to="/about-us" className="hover:underline">About Us</Link>
            <Link to="/charts" className="hover:underline">Charts</Link>
            <Link to="/how-to-play" className="hover:underline">How To Play</Link>
            <Link to="/terms" className="hover:underline">Terms & Condition</Link>
          </nav>
          <Button onClick={() => handleClick('https://matka555.com/app/')}  variant="secondary">Get Free App</Button>
        </div>
      </header>

      <Routes>
            <Route path="/" element={<App />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/charts" element={<Charts />} />
            <Route path="/how-to-play" element={<HowToPlay />} />
            <Route path="/terms" element={<TermsAndConditions />} />

            {/* Add other routes here */}
          </Routes>
 
   
          <footer className="bg-white border-t">
        <div className="container mx-auto px-4 py-8">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <div className="flex items-center space-x-2 mb-4 md:mb-0">
              <div className="w-8 h-8 bg-teal-600 rounded-full flex items-center justify-center text-white font-bold text-xl">
              <img src={myImage} alt="My Image" /></div>
              <span className="text-xl font-bold">Kuber 555</span>
            </div>
            <div className="text-sm text-gray-600 text-center md:text-right">
              <p>Players need to be 18+ in order to register. Underage gambling is prohibited.</p>
              <p>Copyright © 2024 - Kuber 555. All Rights Reserved</p>
            </div>
          </div>
        </div>
      </footer>
</div>
</BrowserRouter>
</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
