import React from 'react';
import '../index.css';
export function Button({ variant = "primary", size = "md", children, ...props }) {
  const baseStyles = "px-4 py-2 rounded font-semibold";
  const sizeStyles = size === "lg" ? "text-lg py-3" : size === "sm" ? "text-sm py-1" : "text-base";
  const variantStyles =
    variant === "secondary"
      ? "bg-teal-600 text-white hover:bg-teal-500"
      : variant === "outline"
      ? "border border-teal-600 text-teal-600 hover:bg-teal-100"
      : "bg-teal-600 text-white hover:bg-teal-500";
  
  return (
    <button className={`${baseStyles} ${sizeStyles} ${variantStyles}`} {...props}>
      {children}
    </button>
  );
}
