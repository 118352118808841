import React from 'react';
import '../index.css';
export const Accordion = ({ children }) => {
  return <div className="accordion">{children}</div>;
};

export const AccordionItem = ({ value, children }) => {
  return <div className="accordion-item">{children}</div>;
};

export function AccordionTrigger({ children }) {
    return (
      <button className="flex justify-between items-center w-full text-left text-xl font-semibold text-teal-600 py-2">
        {children}
      </button>
    );
  }
  
  export function AccordionContent({ children }) {
    return (
      <div className="text-gray-600 text-base py-2">
        {children}
      </div>
    );
  }
  