import React from 'react';

function TermsAndConditions() {
  return (
    <section className="text-center mb-12 m-20">
      <h1 className="text-left text-5xl mb-4">Terms & Conditions</h1>
      
      <h4 className="text-left text-2xl mb-4">
        Kalyan, Main Bazar Jodi Chart Satta Matka Record Old History Historical Data Bracket Results Chart Online Live Book Digits Numbers
      </h4>
      
      <h2 className="text-left text-3xl mb-4 mt-7">Withdraw Information</h2>
      <h4 className="text-left text-2xl mb-4">
        ● If Users Enter Wrong Bank Details, Kuber555 Is Not Responsible.
        <br /><br />
        ● Before requesting a withdrawal, re-check your bank details.
        <br /><br />
        ● If there is no valid wallet balance after submitting a withdrawal request, the request will be automatically declined.
      </h4>

      <h2 className="text-left text-3xl mb-4 mt-7">Unfair Bets</h2>
      <h4 className="text-left text-2xl mb-4">
        If the admin finds any unfair bets, blocking of digits, canning, or match-fixing bets, the admin has full rights to take necessary actions, including blocking the user.
      </h4>

      <h2 className="text-left text-3xl mb-4 mt-7">Cheating Bets</h2>
      <h4 className="text-left text-2xl mb-4">
        If the admin finds any cheating, hacking, or phishing, the admin has full rights to take necessary actions, including blocking the user.
      </h4>
    </section>
  );
}

export default TermsAndConditions;
