import React from 'react';
import '../index.css';
export function Card({ children }) {
    return (
      <div className="bg-white border border-gray-300 rounded-lg shadow-sm">
        {children}
      </div>
    );
  }
  
  export function CardContent({ children, className }) {
    return (
      <div className={`p-4 ${className}`}>
        {children}
      </div>
    );
  }
  
