import React from 'react';

function AboutUs() {
  return (
    <section className="text-center mb-12 m-20">
      <h1 className=" text-left text-5xl  mb-4">About Us</h1>
      <h4 className="text-left text-2xl  mb-4">Kalyan, Main Bazar Jodi Chart Satta Kuber555 Record Old History Historical Data Bracket Results Chart Online Live Book Digits Numbers
<br></br>
<br></br>
Kuber555 is operated by S S international, a company incorporated under the laws of United Kingdom and regulated by the UK authority as the regulatory body responsible holding a (Sub-license with License number 392/JAZ Sub-License GLH- OCCHKTV0707086017 granted on 21.08.2020).</h4>
    
<h2 className=" text-left text-3xl mb-4 mt-7">Kuber555 is into existence for past 30 years with more than 1.38 lac + members.</h2>
    
   <h4 className="text-left text-2xl  mb-4">We have proudly taken our culture from offline to online business and now stand as India's most trusted betting platform. Kuber555 is an international betting platform and has presence in more than 18 countries. We truly value our customers and our endeavor is to provide best customer service and enable our customers to play online games with ease.</h4>
    </section>
  );
}

export default AboutUs;
